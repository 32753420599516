/*
* var bs = document.createElement('script');
bs.setAttribute('src','https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js');
// document.head.appendChild(bs);
document.getElementsByTagName('body')[0].appendChild(bs);
* */

import 'bootstrap'
import './sass/main.scss'
// import 'holderjs'
import 'slick-carousel'
import 'simplelightbox'
import moment from 'moment'
import Masonry from 'masonry-layout'

moment.locale('de');
// get news by json
var arr =  window.location.href.split("/");
var jsonUrl = arr[0] + "//" + arr[2]+"/helper-and-api/aktuelles-articlelist/?page=";
var pcount = 0;

var breakpoint = 768;
var breakpoint2 = 992;

$(function () {

    $("a[href$='pdf']").attr('target','_blank');
    $("a[href$='png']").attr('target','_blank');
    $("a[href$='jpg']").attr('target','_blank');
    $("a[href$='jpeg']").attr('target','_blank');
    $("a[href$='gif']").attr('target','_blank');
    // to Top
    $('#to-top').click(function (e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 600);
    });

    $('a.linkme0').click(function (e) {
        e.preventDefault();
    });

    // Tabs click set hash
    $('.nav-tabs').on('click', 'a[data-toggle="tab"]', function (e) {
        e.preventDefault();
        // add this line
        window.location.hash = $(this).attr('href');
    });

    // Anker
    if(window.location.hash) {
        if (window.location.hash.match(/^#tab([0-9]+)$/)) {
         // anker
            $('a[href="' + window.location.hash + '"]').tab('show');
        }
        else {
            // tabs
            var hash = window.location.hash;
            var $target = $(hash);
            var $off = $target.offset().top;
            $('html, body').animate({
                'scrollTop': $off
            }, 900, 'swing', function () {
                window.location.hash = hash;
            });
        }
    }

    $('.ankerlink').on('click',function(e) {
        e.preventDefault();
        var target = this.hash;
        var $target = $(target);
        var $off = $target.offset().top;
        $('html, body').animate({
            'scrollTop': $off
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });

    $( 'body' ).on( 'click', '.hamburger', function() {
        if($(this).hasClass('is-active')) {
            // closed
            $('body').removeClass('mno');
        }
        else {
            // open
            $('body').addClass('mno');
        }

        $(this).toggleClass('is-active');
    });

    if($('#search').val()) $('.searchline').addClass('open');
    $('.searchline .search-submit').click(function (e) {
        e.preventDefault();
        if($('body').hasClass('mobile')) {
            $('.searchline').submit();
        }
        else {
            if($(this).closest('.searchline').hasClass('open') && $('#search').val()) {
                $('.searchline').submit();
            }
            else {
                $('.searchline').toggleClass('open');
            }
        }
    });

    $('.noview span').click(function (e) {
        e.preventDefault();
        if(!$('#search').val()) {
            $('.searchline').removeClass('open');
        }
        else {
            $('input#search').val('');
        }
    });

    if ($('a.simplelight').length) {
        const gallery = $('a.simplelight').simpleLightbox({
            widthRatio: 0.96,
            animationSlide: true,
            spinner: false,
            disableScroll: false,
        });

        $('.simplelight').on('show.simplelightbox', (e) => {
            e.preventDefault();
            // $('body').addClass('noscroll');
        });
        $('.simplelight').on('closed.simplelightbox', (e) => {
            e.preventDefault();
            // $('body').removeClass('noscroll');
        });
    }

    $( '#dates' ).on( 'click', 'a', function(e) {
        e.preventDefault();
        $( '#dates a' ).removeClass('active');
        $(this).addClass('active');
        $('.Rtable-row').hide();
        var doptionSelected = $(this).attr('href');
        $('.Rtable-row[data-month="'+doptionSelected+'"]').show();
        if(doptionSelected == 'all') {
            $('.Rtable-row').show();
        }
        else {
            $('.Rtable-row[data-month="'+doptionSelected+'"]').show();
        }
    });

    $('#freset').click(function (e) {
        e.preventDefault();
        $('.g-filter a').addClass('active');
        $('select#dates option:first').prop('selected', true);
        selectorator();
    });
    timetableorator();

    var gsDayNames = [
        'So',
        'Mo',
        'Di',
        'Mi',
        'Do',
        'Fr',
        'Sa'
    ];
    const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ];
        // white-bg

    $('.start-slider.white-bg').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev slick-btn"></a>',
        nextArrow: '<a class="slick-next slick-btn"></a>',
        responsive: [
            {
                breakpoint: breakpoint,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    infinite: true,
                }
            }
        ]
    });

    $('.start-slider.red-bg').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 4850,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev slick-btn"></a>',
        nextArrow: '<a class="slick-next slick-btn"></a>',
        responsive: [
            {
                breakpoint: breakpoint,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    infinite: true,
                }
            }
        ]
    });

    $('.group-slider').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: '<a class="slick-prev slick-btn"></a>',
        nextArrow: '<a class="slick-next slick-btn"></a>',
        responsive: [
            {
                breakpoint: breakpoint2,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    infinite: true,
                }
            },
            {
                breakpoint: breakpoint,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '40px',
                }
            }
        ]
    });

    $('.image-slider').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev slick-btn"></a>',
        nextArrow: '<a class="slick-next slick-btn"></a>',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    infinite: true,
                }
            },
            {
                breakpoint: breakpoint,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    infinite: true,
                }
            }
        ]
    });
});

$(window).resize(function(){
    // footerlator();
    //$('body').addClass('blur');

    delay(function(){
        bodyrator();
        flowrator();
        resizeorator();
        fontolator();
        heightorator();
        headerrator();
        if($('.hamburger').hasClass('is-active')) {
            $('.hamburger').click();
        }
    },100);
});

var delay = (function() {
    var timer = 0;
    return function(callback, ms){
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    }
})();

$(window).on("load", function (e) {
    delay(function(){
        flowrator();
        resizeorator();
        heightorator();
        headerrator();
        fontolator();
        infiniterator();
        bodyrator();
    },50);
    // masonry
    const grid = document.querySelector('.masonry-grid');
    if (grid) {
        const msnry = new Masonry(grid, {
            itemSelector: '.grid-item',
            transitionDuration: 0,
            initLayout: false,
            columnWidth: '.grid-sizer',
            percentPosition: true,
        });
        msnry.once('layoutComplete', () => {
            grid.classList.add('load');
        });
        msnry.layout();
    }
});

var isloaded = false;
$(window).on("scroll", function (e) {
    if($(window).scrollTop() + $(window).height() > $(document).height() - $('.site-footer').height() + 100) {
        if(isloaded) infiniterator();
        isloaded = false;
    }
    var $height = $(window).scrollTop();
    if($height > 180) {
        $('.logo').addClass('scrolled');
        $('.fixer').addClass('scrolled');
        $('.header-mobile').addClass('scrolled');
    } else {
        $('.logo').removeClass('scrolled');
        $('.fixer').removeClass('scrolled');
        $('.header-mobile').removeClass('scrolled');
    }
    if($height > 300) {
        $('#to-top').addClass('scrolled');
    } else {
        $('#to-top').removeClass('scrolled');
    }
});

function flowrator() {

    var viewportWidth = $(window).width();
    if (viewportWidth < breakpoint) {
        $('.centerlinks a').css('font-size', '16px');
        $('.centerlinks.big-font a').css('font-size', '18px');
    }
    else {
        $('.centerlinks').each(function () {

            if ($(this).find('li:first').length) {
                var h = $(this).find('li:first').offset().top;
                var h2 = 0;
                $(this).find('li').each(function () {
                    var g = $(this).offset().top;
                    if(g >= h) h2 = g;
                });
                if(h2>h) {
                    var f = $(this).find('li a').css('font-size');
                    var nf = parseFloat(f)*0.95;
                    if(nf > 20) nf = 20;
                    $(this).find('li a').css('font-size', nf);
                    flowrator();
                }
            }
        });
    }
}

var temp;

function bodyrator() {
    var viewportWidth = $(window).width();
    if (viewportWidth < breakpoint) {
        $("body").removeClass("mobile").addClass("mobile");
    }
    else {
        $("body").removeClass("mobile");
    }
}

function noaccent(myString) {
    temp = myString.replace(/[àâä]/g, "a");
    temp = temp.replace(/[éèêë]/g, "e");
    temp = temp.replace(/[îï]/g, "i");
    temp = temp.replace(/[ôö]/g, "o");
    temp = temp.replace(/[ùûü]/g, "u");
    temp = myString.replace(/[ÀÂÄ]/g, "A");
    temp = temp.replace(/[ÉÈÊË]/g, "E");
    temp = temp.replace(/[ÎÏ]/g, "I");
    temp = temp.replace(/[ÔÖ]/g, "O");
    temp = temp.replace(/[ÙÛÜ]/g, "U");

    return temp;
}

function heightorator() {

    var viewportWidth = $(window).width();


    if (viewportWidth < breakpoint) {
        $('.start-slider .rightbox .inner').css('height','auto');
        var h = 0;
        $('.start-slider .rightbox .inner').each(function () {
            var e = $(this).height();
            if(e>h) h = e;
        });
        $('.start-slider .rightbox .inner').height(h + 30);
    }
    else {
        $('.start-slider .rightbox .inner').css('height','100%');
    }
}

function timetableorator() {
    $('#dates').append('<li><a href="all" class="active">alle Termine</a></li>');
    var dates = {};
    $('.Rtable-row').each(function (e) {
        if (!dates.hasOwnProperty($(this).data('month'))) {
            dates[$(this).data('month')] = $(this).data('month');
        }
    });
    $.each( dates, function( key, value ) {
        $('#dates').append('<li><a href="' + value + '">' + value + '</a></li>');
    });
}
// ajax load aktuelles
function infiniterator() {
    if($('#newslist').length) {
        var nid = $('#newslist').data('nid');
        var jurl = jsonUrl + pcount + '&nid='+nid;
        // console.log(jurl);
        var tcount = $('#newslist').data('count');
        if(pcount < tcount){
            $.getJSON(jurl, function(result){
                var items = [];
                $.each(result, function(i,data){
                    //create table
                    var header = data[0] ? '<h3 class="mb-2">'+data[0]+'</h3>' : '';
                    var dateString = '';
                    if(data[3]) {
                        dateString = '<h4 class="date mb-0">'+moment.unix(data[3]).format("dd DD.MM.YYYY")+'</h4>';
                    }
                    /*
                    src="'.mm_auto::rewrite('REX_MEDIA[id=1]', 'rex_700_preload').'"
                    data-src="'.mm_auto::rewrite('REX_MEDIA[id=1]', 'rex_700').'"
                    Later

                    <img src="/images/rex_860/hubble-telescope-turns-25-a-retrospective-look.jpg" class="img-fluid lazy faded" alt="" title="">
                     */
                    var img = data[4] ? '<img src="/images/rex_600/'+data[4]+'" alt="" class="img-fluid">' : '<img src="/images/rex_600/default.png" alt="'+data[0]+'" title="'+data[0]+'" class="img-fluid">';
                    // first without opa
                    if(i > 0) { var opa = ' has_opa';}
                    items.push(
                        '<a href="'+data[1]+'" title="'+data[0]+'">' +
                        '        <div class="row newslist_item '+opa+' mb-4">' +
                        '            <div class="col-12 col-md-5">' +
                        img +
                        '            </div>' +
                        '            <div class="col-12 col-md-7 text pt-2">' +
                        dateString +
                        header +
                        data[2] +
                        '                <p class="more">Mehr erfahren</p>' +
                        '            </div>' +
                        '        </div>' +
                        '</a>'
                    );
                });
                $('#newslist').append(items);
                // footerlator();
                isloaded = true;
            });
            pcount++;
        }
    }

}
// dynamic font size
function fontolator() {

    $('.fonto').each(function () {
        /*
         h5 {
            font-size: 30px;
            @include media-breakpoint-up(md) {font-size: 80%;}
            text-transform: uppercase;
          }
          h3 {
            font-size: 40px;
            @include media-breakpoint-up(md) {
              font-size: 170%;
            }
          }
         */

        var h5 = 0;
        var h3 = 0;
        if($(this).find('.top').length) {
            if (viewportWidth < breakpoint) {
                $(this).find('.top h5').css('font-size','28px');
                $(this).find('.top h3').css('font-size','40px');
            }
            else {
                $(this).find('.top h5').css('font-size','90%');
                $(this).find('.top h3').css('font-size','170%');
            }

            var hh = $(this).find('.top').height();
            // console.log('find top hh: '+hh);
            if($(this).find('.top h5').length) {
                h5 = $(this).find('.top h5').height();
            }
            if($(this).find('.top h3')) {
                h3 = $(this).find('.top h3').height();
            }
            var h35 = h3 + h5;
            var h3n = 0;
            var h5n = 0;

            if(h35 > 0 && h35 > hh) {
                for(var i=9;i<32;i++) {
                    $(this).find('.top h5').css('font-size',i-1);
                    $(this).find('.top h3').css('font-size',i);
                    if($(this).find('.top h3').length) {
                        var h3n = $(this).find('.top h3').height();
                    }
                    if($(this).find('.top h5').length) {
                        h5n = $(this).find('.top h5').height();
                    }
                    if(hh < h5n+h3n) {

                        var viewportWidth = $(window).width();
                        if (viewportWidth < breakpoint) {
                            $(this).find('.top h5').css('font-size',i - 5);
                            $(this).find('.top h3').css('font-size',i - 2);
                            break;
                        }
                        else {
                            $(this).find('.top h5').css('font-size',i - 8);
                            $(this).find('.top h3').css('font-size',i - 4);
                            break;
                        }


                    }
                }
            }
        }
    });

    var windowHeight = $(window).height();
    $('header .nav-mobile .links li a').css('font-size', '8vw');
    if ($('header .nav-mobile .links').height() + 80 > windowHeight) {
        for(var i=9;i<40;i++) {
            $('header .nav-mobile .links li a').css('font-size', i);
            // console.log($('header .nav-mobile .links').height() + ' ' + windowHeight);
            if ($('header .nav-mobile .links').height()+80 > windowHeight) {
                $('header .nav-mobile .links li a').css('font-size',i - 3);
                break;
            }
        }
     }
}
// sticky footer
/*
function footerlator() {
    let footerHeight = 0;
    let documentHeight = 0;
    let headerHeight = 0;
    let windowHeight = 0;
    const $footer = $('.page-footer');
    const $header = $('header');
    footerHeight = $footer.height();
    documentHeight = $('#main').height();
    headerHeight = $header.height();
    windowHeight = $(window).height();

    if ((documentHeight + footerHeight + headerHeight) < windowHeight) {
        $footer.addClass('sticky');
    } else {
        $footer.removeClass('sticky');
    }
}
*/

function headerrator() {
    /*
    var h = $('header').height();
    if(h) $('#main').css('margin-top',h);
    else $('#main').css('margin-top',112);
    */
    var wh = $(window).height();
    $('.nav-mobile').height(wh);
}

function resizeorator() {
    // console.log('resize');
    // slick on startpage
    var w = $('.start-slider .leftbox').width();
    $('.start-slider.red-bg .slick-dots').width(w - 10);
    var d = $('.start-slider img').height();
    $('.start-slider .slick-btn').css('top', d -  45);
    var viewportWidth = $(window).width();
    if (viewportWidth < breakpoint) {
        $('.start-slider.white-bg .slick-btn').css('top', d -  45);
    }
    else {
        $('.start-slider.white-bg .slick-btn').css('top', d -  75);
        $('.start-slider.white-bg .slick-btn.slick-next').css('margin-right', $('.start-slider.white-bg .rightbox').width() + 5);
    }

    var di = $('.group-slider img').height();
    $('.group-slider .slick-btn').css('top', di -  35);
    var di = $('.image-slider img').height();
    $('.image-slider .slick-btn').css('top', di -  35);
    // $('body').removeClass('blur');
}

// lazy images
document.addEventListener('DOMContentLoaded', () => {
    const images = document.querySelectorAll('.lazy');
    const lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));

    if (!('IntersectionObserver' in window)) {
        images.forEach((image) => {
            handleImage(image);
        });
        $('.lazy-background').each(function () {
            const s = $(this).attr('style');
            const y = s.replace('_preload', '');
            $(this).attr('style', y);
        });
    } else {
        // BG
        const lazyBackgroundObserver = new IntersectionObserver(((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    const x = entry.target.style.backgroundImage;
                    const y = x.replace('_preload', '');
                    entry.target.style.backgroundImage = y;

                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        }));

        lazyBackgrounds.forEach((lazyBackground) => {
            lazyBackgroundObserver.observe(lazyBackground);
        });

        const config = {
            rootMargin: '100px 0px',
            threshold: 0.01,
        };
        const observer = new IntersectionObserver(observeImages, config);

        images.forEach((image) => {
            observer.observe(image);
        });

        function observeImages(entries) {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    observer.unobserve(entry.target);
                    handleImage(entry.target);
                }
            });
        }
    }
});
